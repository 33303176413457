<script setup lang="ts">
import IconLoading from '@/assets/icons/loading.svg'

defineProps({
  wrapScreen: {
    type: Boolean,
    default: false,
  },
  wrapScreenWithSubmenu: {
    type: Boolean,
    default: false,
  },
})

const classScreenHeight =
  'h-[calc(100vh-45px)] md:h-[calc(100vh-81px)] lg:h-[calc(100vh-164px)]'

const classScreenWithSubcategoriesHeight =
  'h-[calc(100vh-85px)] md:h-[calc(100vh-121px)] lg:h-[calc(100vh-204px)]'
</script>

<template>
  <div
    class="flex grow items-center justify-center py-12"
    :class="
      (wrapScreen && classScreenHeight) ||
      (wrapScreenWithSubmenu && classScreenWithSubcategoriesHeight)
    "
  >
    <IconLoading class="h-7 w-7 text-p-500" />
  </div>
</template>
